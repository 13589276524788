/* CartPopup.css */

.cart-popup {
    position: fixed;
    top: 0;
    right: -380px; /* Offscreen initially, adjust this value based on your content width */
    width: 380px; /* Adjust the width as needed */
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    z-index: 999;
    transition: right 0.3s ease-in-out; /* Animation transition */
  }
  
  .cart-popup.open {
    right: 0; /* Slide in from the right */
  }
  
  .close-button {
    cursor: pointer;
    background-color: #f3f4f6;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    color: #807b7b;
    transition: 0.5s;
  }
  
  .close-button:hover {
    background-color: #009f7f;
    color: #ffffff;
  }
  
  .cart-popup-content {
    padding: 20px;
  }
  
  .popup__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .popup__header > span {
    cursor: pointer;
    font-size: 30px;
  }
  
  .countinue__shoping {
    text-align: center;
    cursor: pointer;
    padding-top: 15px;
    margin-left: 20%;
    color: #296dc1;
  }
  
  .cart__product {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .cart__image {
    width: 40%;
  }
  
  .cart__empty {
    margin-top: 60%;
    text-align: center;
  }
  
  .cart__emptyemoji {
    text-align: center;
  }
  .open .cart__empty {
    animation: cartempty 1s ease-in;
  }
  
  .open .cart__emptyemoji {
    animation: cartemoji 1s ease-in;
  }
  
  @keyframes cartempty {
    0% {
      transform: translateX(-400px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  
  @keyframes cartemoji {
    0% {
      transform: translateX(400px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  
  .cart__image > img {
    width: 100px;
    height: 100px;
  }
  
  .cart__productdetails {
    width: 60%;
  }
  
  .total_add {
    display: flex;
    width: 100px;
    justify-content: space-between;
  }
  
  .total_add .minus_cart,
  .total_add .plus_cart {
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
  }
  
  .minus {
    font-weight: bold;
    font-size: 20px;
    cursor: not-allowed;
  }
  
  .amount__quantity {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .cart_price {
    font-weight: bold;
    
  }
  
  .subtotal {
    font-weight: bold;
    /* color: #296dc1; */
    padding-top: 10px;
  }
  
  .quantity {
  }
  
  .cart__productdetails > span {
    padding-bottom: 20px;
  }
  
  .cart__productdetails h6 {
    font-size: 0.9rem;
  }
  
  .plus__minus {
    float: left;
  }
  
  .cart-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: none;
  }
  
  .cart-backdrop.open {
    display: block;
  }
  
  .total_cart_price {
    position: fixed;
    bottom: 0;
    width: 26%;
    height: 7%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00778b;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .total_cart_price:hover {
    cursor: pointer;
  }
  .total_cart_checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    font-weight: bolder;
  
    height: 100%;
  }
  .checkout_total_price {
    background-color: #fff;
    padding: 5px;
    color: #00778b;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: max-content;
  }
  
