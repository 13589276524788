@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

.main_footer {
  width: 100%;
  font-family: "Jost", sans-serif;
  font-size: 0.9rem;
  margin-top: 3%;
  background-color: #fafafa;
  color: #333333;
}

.footer__sub {
  width: 100%;
  border: 1px solid #dedede;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.footer_address{
  padding-top: 5%;
}
.footer_sub {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.footer_first {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
}
.footer_logo_address {
  width: 50%;
}
.footer_logo {
  margin-top: 10%;
}
.footer_logo > img {
  height: 55px;
}
.footer_content {
  width: 50%;
}
.footer_second {
  width: 55%;
  height: auto;
  padding-top: 0%;
}

.footer_social_media {
  display: flex;
  justify-content: center;
  width: 60%;
  gap: 20px;
  margin-top: 15%;
}
.footer_content_links {
  padding-left: 10%;
  padding-top: 12%;

}
.footer_email_button {
  width: 100%;
  display: flex;
}
.footer_email {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

}
.footer_email input[type="email"] {
  width: 60%;
  padding: 1%;
  border: 1px solid #dedede;
  outline: 1px solid #dedede;
  border-radius: 10px;
}
.footer_email button {
  padding: 1.3% 10%;
  margin-left: 2%;
  border-radius: 10px;
  background-color: #296dc1;
  color: #ffffff;
  letter-spacing: 1.5px;
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
}
.footer_content_others {
  display: flex;
  width: 100%;
  margin-left: 3%;
  padding-top: 3%;
}
.footer_time {
  width: 70%;
}
.footer_others {
  width: 30%;
}
.footer_time_2 {
  width: 80%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

.social {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.social-text {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  transition-timing-function: ease;
}

.social:hover .social-text {
  visibility: visible;
}
.social_1 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.social_1:hover .social-text {
  visibility: visible;
}
.social-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.footer_footer {
  width: 100%;
  min-height: 100px;
  height: auto;
  background-color: #fff;
}
.footer_footer_1 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100px;
}
.footer_footer_1_1 {
  width: 58%;
  margin-left: 2%;
}
.footer_footer_1_2 {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 1072px) {
  .footer_sub {
    flex-direction: column;
  }
  .footer_first {
    width: 100%;
    justify-content: center;
  }
  .footer_second {
    width: 100%;
  }
  .footer_logo_address {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer_footer_1 {
    display: flex;
    flex-direction: column;
  }
  .footer_footer_1_1,
  .footer_footer_1_2 {
    width: 95%;
  }
}
@media all and (max-width: 500px) {
  .footer_first {
    flex-direction: column;
    align-items: center;
  }
  .footer_content_links {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
  }
  .footer_social_media {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 0 20px 0;
  }
  .footer_logo_address {
    width: 100%;
  }
  .footer_content {
    width: 100%;
  }
  .footer_content_others {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_email {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 40px;
  }
  .footer_email input[type="email"] {
    width: 90%;
    margin-bottom: 2%;
  }
  .footer_email button {
    width: 90%;
    margin-top: 2%;
    margin-left: 0;
  }
  .footer_logo {
    margin: 0;
  }
  .footer_others {
    width: 100%;
    text-align: center;
  }
  .footer_time {
    width: 100%;
    margin: 2% 0 4% 2%;
  }
  .footer_footer_1_2 {
    flex-direction: column;
    align-items: center;
  }
}

.footer_content_others a,
.footer_footer_1_2 > a,
.footer_content_links a,
.footer_social_media a {
  text-decoration: none;
  color: #333333;
}

.privacy_poli:hover,
.terms_condi:hover,
.vendoe_res:hover,
.my_acc:hover,
.privacy_pol:hover,
.terms_cond:hover,
.store_dir:hover,
.about:hover {
  color: #296dc3;
}
