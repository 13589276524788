@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

.stores {
  width: 100%;
  height: 100vh;
  font-family: "Jost", sans-serif;
}

.store__list {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.store_search_content {
  margin-top: 1%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.store_search {
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.store_search_category,
.store_search_city {
  width: 40%;
  position: relative;
}
.store_search_category input[type="search"] {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
}
.store_search_city input[type="search"] {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  margin-left: 1%;
}
.store_search_button {
  background-color: #009f7f;
  padding: 14px;
  color: #fff;
  border-radius: 0px 8px 8px 0px;
  margin-left: -5px;
}
.store_search_category input[type="search"]:focus + .filtered_results {
  display: block;
}
.store_search_category input[type="search"]:focus {
  border: 1px solid #009f7f;
}
.store_search_city input[type="search"]:focus {
  border: 1px solid #009f7f;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  padding: 0;
  margin-top: 2px;
  list-style: none;
  text-align: left;
  z-index: 15;
}
.store {
  width: 30%;
  margin: 3% 0 0 3%;
  position: relative;
  height: 45%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.store_banner {
  height: 70%;
  background: url("../../assets/default_banner.jpg");
  background-size: cover;
  object-fit: fill;
  border-bottom: 3px solid #17a2b8;
}

.store_banner_content {
  position: relative;
  z-index: 10;
}

.store_title {
  font-weight: 600;
  font-size: 20px;
  padding: 20px 0 0px 20px;
}

.store__title {
  cursor: pointer;
}

.store_rate {
  padding: 0 0 10px 20px;
}

.store_rate_icons {
  display: flex;
  gap: 2px;
}

.store_banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.store_mail,
.store_phone {
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
  padding-left: 20px;
  padding-bottom: 10px;
  gap: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.store_mail_back {
  display: flex;
  width: 70%;
  align-items: center;
  height: auto;
  gap: 10px;
  border-radius: 50px;
  background-color: #617e7f;
  box-shadow: -70px 0px 8px inset rgba(97, 126, 127, 0.2);
}

.store_mail svg,
.store_phone svg {
  background-color: #525252;
  height: 22px;
  width: 22px;
  padding: 5px;
  border-radius: 50%;
}

.store_visit {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  gap: 15px;
  cursor: pointer;
}

.store_visit > a,
.store_visit > span {
  padding: 5px 15px;
  background-color: #17a2b8;
  text-decoration: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  transition: 0.3s;
}

.inquiry_btn {
  display: flex;
  gap: 4%;
  align-items: center;
  cursor: pointer;
}

.visit {
  font-weight: 600;
}

.store_visit > a:hover {
  background-color: #000;
}

.inquiry {
  cursor: help;
}

.store_logo img {
  width: 70px;
  height: 70px;
}

.store_logo {
  position: absolute;
  top: 55%;
  left: 5%;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  border: 2px solid #fafafa;
}

.store_logo img {
  border-radius: 50%;
}

/* Styling for the overlay background */
/* Styling for the overlay background */
.inquiry_overlay {
  position: fixed;
  font-family: "Jost", sans-serif;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* cursor: pointer; */
  gap: 2%;
}

.inquiry_popup {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 33%;
  height: 85vh;
  max-height: auto;
}

.inquiry_popup input[type="text"],
.inquiry_popup textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input__email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inquiry_popup input[type="email"],
.inquiry_popup input[type="phone"] {
  width: 48%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inquiry_popup input[type="email"]::placeholder,
.inquiry_popup input[type="phone"]::placeholder,
.inquiry_popup input[type="text"]::placeholder,
.inquiry_popup textarea::placeholder {
  color: #757575;
  font-size: 14px;
}

.textarea {
  width: 100%;
  height: 50px;
}

.shipping_address_textarea {
  height: 100px;
}

.inquiry_popup button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.heading_close {
  display: flex;
  justify-content: space-between;
}
.heading_popup {
  width: 100%;
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.inquiry_popup .close_button {
  cursor: pointer;
  padding: 10px;
  background: none;
  border: none;
  color: #000;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 20px;
  /* margin-bottom: 2%; */
}

.product__logo {
  position: absolute;
  top: -50px;
  left: 40%;
}

.product__logo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.popup__product {
  text-align: center;
  padding: 10px 0 10px;
  width: 100%;
}

.popup__product h3 {
  font-weight: 600;
  margin-top: 15px;
  font-size: 18px;
}

.popup__header {
  background-color: #f9f9f9;
  padding: 20px 20px 0;
  border-radius: 10px;
}

.form {
  padding: 20px;
}

.thankyou__message {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.thankyou__message p {
  text-align: center;
}

.icon {
  font-size: 2rem;
  padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .inquiry_popup {
    width: 50%;
    height: auto;
  }
}

@media screen and (max-width: 750px) {
  .store {
    width: 45%;
    height: 260px;
    justify-content: center;
  }
  .inquiry_popup {
    width: 60%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .inquiry_popup {
    width: 70%;
    height: auto;
  }
  .input__email {
    flex-direction: column;
  }
  .inquiry_popup input[type="email"],
  .inquiry_popup input[type="phone"] {
    width: 100%;
  }
  .textarea {
    width: 100%;
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .store {
    width: 90%;
    height: 260px;
    margin: 0;
    margin-top: 5%;
    justify-content: center;
  }

  .store_banner {
    height: 190px;
  }

  .store__list {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
  }

  .stores {
    min-height: 200vh;
  }
  .inquiry_popup {
    width: 400px;
    max-width: 90%;
  }
  .textarea {
    max-height: auto;
  }
}
