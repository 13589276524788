@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

.vendor {
  width: 100%;
  height: 100vh;
  font-family: "Jost", sans-serif;
  color: 333333;
}

.vendor__info {
  width: 100%;
  height: 75%;
  line-height: 2px;
}

.vendor__banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%;
  background: url("../../assets//default_banner.jpg");
  background-size: 100% 100%;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.15);
}

.vendor__banner h1 {
  color: #fff;
  margin: auto;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 25px;
  text-align: center;
}

.vendor__line {
  width: 100px;
  height: 2px;
  background-color: #fff;
}

.vendor__address {
  height: 25%;
  background-color: #2f2f2f;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding-left: 200px;
}

.store__hours {
  /* line-height: 30px; */
  padding-top: 10px;
}

.store__hours span {
  font-size: 22px;
  color: #20abc1;
}

.address_vendor {
  padding-top: 10px;
}

.vendor__addressline {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.vendor__addressline span,
.vendor__phonemail span {
  font-size: 22px;
  color: #20abc1;
}

.vendor__phonemail {
  display: flex;
  gap: 30px;
}

.phone__vendor,
.mail__vendor {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.vendor__logo {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 60%;
  left: 5%;
  /* background-color: #fff; */
  border-radius: 50%;
  /* padding: 4px; */
  /* border: 2px solid #fafafa; */
}

.vendor__logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.category__name {
  text-transform: capitalize;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
}

.category__name:hover {
  transform: scale(1.05);
  color: #00778b;
}

.page__number {
  font-size: 20px;
  border: 1px solid #8d929d;
  padding: 5px 13px;
  margin: 0 1%;
  border-radius: 5px;
}

.buttonsmenu {
  display: flex;
  justify-content: center;
}

.all_filter_sticky {
  position: sticky;
  top: 3%;
  height: 75vh;
  overflow-x: hidden;
}

.category__list .active {
  color: #00778b;
  font-weight: bold;
  transform: none;
}

.category__list {
  width: 100%;
  margin-left: 10%;
  line-height: 2rem;
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.02);
}

.card-price {
  color: #525a65;
  font-weight: bold;
  font-size: 14px;
}

.card-img-top:hover,
.card-text:hover {
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.card-text {
  color: #8d929d;
  font-size: 16px;
}

/* add to cart */

.addtocard {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cartadd {
  width: 80%;
  text-align: center;
  background-color: #f3f4f6;
  border-radius: 5px 0 0 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.sub-btn {
  width: 20%;
  text-align: center;
  background-color: #e5e7eb;
  border-radius: 5px 0 0 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.add-sym {
  width: 20%;
  text-align: center;
  background-color: #e5e7eb;
  border-radius: 0 5px 5px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.quantity-btn {
  width: 80%;
  text-align: center;
  background-color: #e5e7eb;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addtocard:hover {
  cursor: pointer;
  color: #fff;
}

.addtocard:hover .cartadd {
  background-color: #20abc1;
}

.aaddtocarddd:hover .sub-btn {
  background-color: #00778b;
}

.addtocard:hover .quantity-btn {
  background-color: #20abc1;
}

.addtocard:hover .add-sym {
  background-color: #00778b;
}

.addtocard.active .quantity-btn,
.addtocard.active .add-sym,
.addtocard.active .sub-btn {
  background-color: #20abc1;
  color: #fff;
}

.addtocard.active .add-sym:hover,
.addtocard.active .sub-btn:hover {
  background-color: #00778b;
}

/* add to cart */

.popup {
  position: fixed;
  top: 0;
  right: -100%;
  width: 200px;
  height: 100vh;
  transition: right 0.3s ease;
  z-index: 100;
  overflow-y: scroll;
}

.popup.show {
  right: 0;
  width: 33%;
}

.show {
  display: block;
}

.cart-details {
  position: absolute;
  top: 0;
  color: black;
  width: 100%;
  z-index: -1;
}

@media screen and (max-width: 750px) {
  .vendor__banner {
    width: 100%;
  }

  .vendor__banner h1 {
    font-size: 2rem;
  }

  .vendor__line {
    width: 50px;
  }
}

/* Responsive Adjustments */

@media (max-width: 1030px) {
  .header__left {
    width: 30%;
  }

  .header__center {
    width: 70%;
  }

  .header__right {
    display: none;
  }
  .vendor__address {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 853px) {
  .header__left {
    width: 40%;
  }

  .header__center {
    width: 60%;
  }

  .header__right {
    display: none;
  }
}

@media (max-width: 650px) {
  .vendor__header {
    flex-direction: column;
    align-items: center;
  }

  .header__left,
  .header__center {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .header__right {
    display: none;
  }
}


@media (max-width: 971px) {
  .vendor__logo {
    top: 58%;
    border-radius: 50%;
  }

  .vendor__logo img {
    width: 90%;
    height: 70%;
  }
}

@media (max-width: 879px) {
  .vendor__logo {
    top: 60%;
    border-radius: 50%;
  }

  .vendor__logo img {
    width: 80%;
    height: 60%;
  }

  .vendor__address {
    /* justify-content: center; */
    padding-left: 200px;
  }
}

@media (max-width: 715px) {
  .vendor__logo {
    top: 56%;
    left: 40%;
    border-radius: 50%;
  }
  .vendor__address {
    height: auto;
    padding: 50px 0 10px 30px;
  }

  .address_vendor {
    display: flex;
    flex-wrap: wrap;
  }

  .vendor__addressline p {
    line-height: 20px;
  }
  .vendor__banner h1 {
    padding-top: 80px;
  }

}

@media (max-width: 500px) {
  .vendor__logo {
    top: 43%;
  }

  .vendor__banner {
    height: 60%;
  }

.vendor__addressline {
  padding-top: 10px;

}
  .vendor__banner h1 {
    padding-top: 70px;
    font-size: 1.5rem;
  }

}

@media (max-width: 394px) {
  .vendor__logo {
    border: solid red;
  }
}