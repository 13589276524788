.mini-cart {
    width: 100px;
    height: 100px;
    background-color: #00778b;
    border-radius: 10px 0 0 10px;
  }
  
  .total-price {
    width: fit-content;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    color: #00778b;
    font-weight: 600;
    margin: 15px auto;
  }
  
  .cart-icon {
    display: flex;
    padding-top: 10px;
    gap: 10px;
    align-items: center;
    font-weight: bold;
    color: #fff;
    justify-content: center;
  }
  